import React from "react";
import styled from "styled-components";
import {gsap, TimelineLite} from "gsap";
import {DrawSVGPlugin} from "gsap/dist/DrawSVGPlugin";
import {usePathname} from "next/navigation";
import {useGSAP} from "@gsap/react";
import {text} from "@/styles/globalStyleVars";

const PageTransition = () => {
    // const pathName = usePathname();
    // gsap.registerPlugin(DrawSVGPlugin);
    //
    // useGSAP(() => {
    //     const hide = ".page-change";
    //     const logoWhite = ".preloader-logo";
    //
    //     // Main animation timeline
    //     const tl = gsap.timeline({ repeat: 0 });
    //
    //     // Load both animations in parallel for faster execution
    //     tl.fromTo(
    //         "svg .first-load",
    //         { drawSVG: "0% 100%", opacity: 1 },
    //         {
    //             duration: 0.8,
    //             drawSVG: "100%",
    //             fill: "#cb5f28",
    //             stagger: 0.1,
    //         }
    //     )
    //         .fromTo(
    //             "svg .second-load",
    //             { drawSVG: "0% 100%", opacity: 1 },
    //             {
    //                 duration: 1,
    //                 drawSVG: "100%",
    //                 fill: "#145c8c",
    //                 stagger: 0.1,
    //                 delay: 0.4, // Start second animation faster
    //             },
    //             "-=0.5"
    //         )
    //         .to(logoWhite, { duration: 0.5, autoAlpha: 0 }, "-=0.5")
    //         .to(hide, { duration: 0.5, autoAlpha: 0 }, "-=0.4");
    //
    //     // Cleanup function to prevent memory leaks
    //     return () => {
    //         tl.kill(); // Kill the timeline
    //         gsap.killTweensOf(".first-load, .second-load"); // Kill tweens
    //     };
    // }, [pathName]);
    //
    //

    const pathName = usePathname();
    gsap.registerPlugin(DrawSVGPlugin);

    useGSAP(() => {
        const hide = ".page-change";
        const logoWhite = ".preloader-logo";

        // Main animation timeline with no initial delay
        const tl = gsap.timeline({ repeat: 0 });

        // Animations running with no delay
        tl.fromTo(
            "svg .first-load",
            { drawSVG: "0% 100%", opacity: 1 },
            {
                duration: 0.5, // Reduced duration for faster drawing
                drawSVG: "100%",
                fill: "#cb5f28",
                stagger: 0.05, // Reduced stagger for quicker sequential animation
            }
        )
            .fromTo(
                "svg .second-load",
                { drawSVG: "0% 100%", opacity: 1 },
                {
                    duration: 0.7, // Reduced duration for faster animation
                    drawSVG: "100%",
                    fill: "#145c8c",
                    stagger: 0.05, // Reduced stagger
                },
                "-=0.3" // Overlap with the first animation
            )
            .to(logoWhite, { duration: 0.3, autoAlpha: 0 }, "-=0.3") // Shortened fade-out time
            .to(hide, { duration: 0.3, autoAlpha: 0 }, "-=0.2"); // Shortened fade-out time
        sessionStorage.setItem("loader", false);

        // Cleanup function to prevent memory leaks
        return () => {
            tl.kill(); // Kill the timeline
            gsap.killTweensOf(".first-load, .second-load"); // Kill tweens
            // Save data to sessionStorage
        };
    }, [pathName]);



    return (
        <StyledComponent className={"page-change"}>

            <svg version="1.1" id="Layer_1" x="0px" y="0px"
                 viewBox="0 0 1920 1080">
                <polygon className="st0 first-load" points="602.4,497.6 602.4,517.3 537.3,517.3 537.3,432.1 601.8,432.1 601.8,451.8 561.9,451.8 561.9,463.9
		595.7,463.9 595.7,483.6 561.9,483.6 561.9,497.6"/>
                <path className="st0 first-load" d="M601,517.1v0.1V517.1L601,517.1z"/>
                <polygon className="st0 first-load" points="672.9,517.2 646.3,517.2 636.8,502 636.8,502 626.9,517.1 601.1,517.1 622.6,485.3 601.4,454 628,454
		637.2,468.4 637.2,468.4 637.3,468.3 646.3,453.9 672.2,453.9 651.2,485 651.1,485.1"/>
                <path className="st0 first-load" d="M677.1,542.5V454h20.5l1.4,6.6h1c1.8-2.3,4-4.2,6.8-5.6c2.7-1.4,6-2.1,9.6-2.1s9.2,1.1,13.2,3.3
		c4,2.2,7.3,5.6,9.7,10.2s3.7,10.6,3.7,18s-1.4,13.3-4.1,18.4c-2.7,5.1-6.8,9-12.1,11.6c-5.4,2.7-12,4-20,4s-1.8,0-2.7,0
		s-1.8,0-2.6,0v24.3h-24.4L677.1,542.5z M706.8,500.2c3.9,0,6.9-1.1,8.8-3.2c1.9-2.1,2.9-5.7,2.9-10.8s-0.4-6.3-1.2-8.3
		s-1.9-3.4-3.4-4.1c-1.5-0.8-3.2-1.2-5.2-1.2s-2.5,0.2-3.9,0.5c-1.3,0.3-2.5,0.8-3.5,1.5v25.1c0.8,0,1.6,0.2,2.6,0.3
		c0.9,0.1,1.8,0.2,2.7,0.2H706.8L706.8,500.2z"/>
                <path className="st0 first-load" d="M812.1,486.3c0-7-1.1-13-3.3-18s-5.4-8.9-9.8-11.5c-4.3-2.7-9.4-4-16.3-4c-6.9,0-12.7,1.2-17.5,3.9
		s-8.4,6.4-10.8,11.3c-2.5,4.9-3.7,9.8-3.7,17.4c0,7.6,1.3,13.9,3.9,18.7c2.6,4.9,6.7,8.5,12.3,10.8c5.5,2.3,12.7,3.5,21.6,3.5v0.1
		h0.3c3.1,0,6.3-0.1,9.5-0.4c3.2-0.3,6.5-0.8,10.1-1.5v-17.9c-2.6,0.5-5.3,0.9-8.1,1.2c-2.8,0.3-3.2,0.5-8,0.5s-8.4-0.3-10.9-1
		c-2.5-0.6-4.1-1.7-5-3.2c-0.6-1-1-2.3-1.2-3.8h36.9V486.3z M775.1,478.9c0.1-2.6,0.4-4.7,0.8-6.2c0.5-1.7,1.3-3,2.4-3.7
		c1-0.7,2.5-1.1,4.1-1.1c1.6,0,3,0.4,4.1,1.1c1,0.7,1.8,1.9,2.3,3.7c0.4,1.5,0.7,3.6,0.7,6.2H775.1z"/>
                <path className="st0 first-load" d="M819.9,517.2V454h19.9l1.9,8h1c1.5-3.2,3.7-5.5,6.4-6.9s5.8-2.1,9.3-2.1s1.9,0,2.8,0.1s1.8,0.2,2.4,0.3v22
		c-1-0.2-2.2-0.4-3.4-0.5s-2.4-0.1-3.4-0.1c-1.6,0-3.2,0.2-4.8,0.5s-3.1,0.8-4.4,1.4s-2.4,1.4-3.2,2.2v38.3H819.9z"/>
                <path className="st0 first-load" d="M883.5,447.7c-5,0-8.7-1.1-11.1-3.2s-3.5-5.1-3.5-8.9s1.2-6.9,3.5-8.9s6-3.1,11.1-3.1s8.8,1,11.1,3.1
		s3.5,5.1,3.5,8.9s-1.2,6.8-3.5,8.9S888.6,447.7,883.5,447.7z M871.3,517.2V454h24.5v63.2H871.3z"/>
                <path className="st0 first-load" d="M965.9,486.3c0-7-1.1-13-3.3-18c-2.2-5-5.4-8.9-9.8-11.5c-4.3-2.7-9.4-4-16.3-4s-12.7,1.2-17.5,3.9
		s-8.4,6.4-10.8,11.3c-2.5,4.9-3.7,9.8-3.7,17.4c0,7.6,1.3,13.9,3.9,18.7c2.6,4.9,6.7,8.5,12.3,10.8c5.5,2.3,12.7,3.5,21.6,3.5v0.1
		h0.3c3.1,0,6.3-0.1,9.5-0.4c3.2-0.3,6.5-0.8,10.1-1.5v-17.9c-2.6,0.5-5.3,0.9-8.1,1.2c-2.8,0.3-3.2,0.5-8,0.5
		c-4.8,0-8.4-0.3-10.9-1c-2.5-0.6-4.1-1.7-5-3.2c-0.6-1-1-2.3-1.2-3.8h36.9V486.3z M928.9,478.9c0.1-2.6,0.4-4.7,0.8-6.2
		c0.5-1.7,1.3-3,2.4-3.7c1-0.7,2.5-1.1,4.1-1.1c1.6,0,3,0.4,4.1,1.1c1,0.7,1.8,1.9,2.3,3.7c0.4,1.5,0.7,3.6,0.7,6.2H928.9z"/>
                <path className="st0 first-load" d="M973.8,517.2V454h20.1l1.6,6h1c2.2-2.3,4.8-4,7.8-5.2s6.3-1.8,9.9-1.8s8.1,0.9,11.5,2.6c3.4,1.8,6,4.6,8,8.5
		c1.9,3.9,2.9,9,2.9,15.3v37.8h-24.4V481c0-2.8-0.5-4.6-1.6-5.7c-1.1-1-2.6-1.5-4.5-1.5s-2,0.1-3,0.4s-1.8,0.6-2.7,1
		c-0.8,0.4-1.5,1-2.2,1.6v40.4H973.8L973.8,517.2L973.8,517.2z"/>
                <path className="st0 first-load" d="M1080.8,518.4c-7.7,0-14.3-1.2-19.6-3.7s-9.4-6.1-12.2-11c-2.8-4.8-4.2-10.9-4.2-18s1.5-13.4,4.5-18.3
		s7.3-8.5,12.9-10.9s12.4-3.6,20.3-3.6s5.4,0.1,7.9,0.3c2.5,0.2,4.8,0.6,7,1v19c-2-0.3-3.8-0.5-5.7-0.7c-1.8-0.2-3.7-0.2-5.7-0.2
		c-3.8,0-6.9,0.5-9.2,1.4c-2.4,0.9-4.1,2.4-5.2,4.3c-1.1,2-1.6,4.4-1.6,7.5s0.6,5.8,1.7,7.7c1.1,2,2.8,3.4,4.9,4.3
		c2.2,0.9,4.9,1.4,8.2,1.4s3.9-0.2,5.9-0.5c1.9-0.3,4.2-0.7,6.7-1.3v19c-2.2,0.7-4.8,1.2-7.6,1.6c-2.9,0.4-5.8,0.6-8.9,0.6h-0.1
		L1080.8,518.4z"/>
                <path className="st0 first-load" d="M1164.2,486.3c0-7-1.1-13-3.3-18c-2.2-5-5.4-8.9-9.8-11.5c-4.3-2.7-9.4-4-16.3-4c-6.9,0-12.7,1.2-17.5,3.9
		c-4.8,2.7-8.4,6.4-10.8,11.3c-2.5,4.9-3.7,9.8-3.7,17.4c0,7.6,1.3,13.9,3.9,18.7c2.6,4.9,6.7,8.5,12.3,10.8
		c5.5,2.3,12.7,3.5,21.6,3.5v0.1h0.3c3.1,0,6.3-0.1,9.5-0.4c3.2-0.3,6.5-0.8,10.1-1.5v-17.9c-2.6,0.5-5.3,0.9-8.1,1.2
		c-2.8,0.3-3.2,0.5-8,0.5c-4.8,0-8.4-0.3-10.9-1c-2.5-0.6-4.1-1.7-5-3.2c-0.6-1-1-2.3-1.2-3.8h36.9V486.3z M1127.2,478.9
		c0.1-2.6,0.4-4.7,0.8-6.2c0.5-1.7,1.3-3,2.4-3.7c1-0.7,2.5-1.1,4.1-1.1s3,0.4,4.1,1.1c1,0.7,1.8,1.9,2.3,3.7
		c0.4,1.5,0.7,3.6,0.7,6.2H1127.2z"/>
                <polygon className="st0 first-load" points="1277.1,432.1 1247.6,482.3 1247.6,517.2 1222.7,517.2 1222.7,482.5 1192.1,432.1 1218.3,432.1
		1235.6,461.4 1252.8,432.1"/>
                <path className="st0 first-load" d="M1302,518.4c-6.9,0-12.8-1.2-17.8-3.7s-8.8-6.2-11.5-11s-4.1-10.8-4.1-17.9s1.3-13.2,3.9-18.1s6.4-8.5,11.4-11
		s11-3.7,18.1-3.7s13.1,1.3,18.1,3.8s8.8,6.2,11.4,11.1s3.9,10.8,3.9,17.9s-1.3,13.1-4,18s-6.5,8.5-11.5,11S1309,518.5,1302,518.4
		L1302,518.4L1302,518.4z M1302,500.3c2,0,3.6-0.4,4.9-1.3c1.3-0.8,2.3-2.3,3-4.4s1-5,1-8.8s-0.3-6.9-1-9s-1.7-3.6-3-4.5
		c-1.3-0.8-2.9-1.3-4.9-1.3s-3.5,0.4-4.9,1.3c-1.3,0.8-2.3,2.3-3,4.4s-1,5.1-1,8.9s0.3,6.8,1,8.9s1.7,3.6,3,4.5
		C1298.4,499.8,1300,500.3,1302,500.3z"/>
                <path className="st0 first-load" d="M1366.4,518.3c-4.4,0-8.3-0.9-11.7-2.6c-3.4-1.8-6.1-4.6-8.1-8.5s-3-9-3-15.2v-38h24.4v36.2
		c0,2.8,0.5,4.7,1.6,5.7s2.5,1.6,4.2,1.6s1.8-0.1,2.8-0.4c0.9-0.2,1.8-0.6,2.7-1.1c0.8-0.5,1.6-1,2.1-1.6v-40.5h24.4v63.2h-20.1
		l-1.6-6h-0.9c-2.3,2.3-4.8,4.1-7.6,5.3C1372.8,517.6,1369.7,518.2,1366.4,518.3L1366.4,518.3L1366.4,518.3z"/>
                <path className="st0 first-load" d="M1417.1,517.2V454h19.9l1.9,8h1c1.5-3.2,3.7-5.5,6.4-6.9s5.8-2.1,9.3-2.1s1.9,0,2.8,0.1s1.8,0.2,2.4,0.3v22
		c-1-0.2-2.2-0.4-3.4-0.5s-2.4-0.1-3.4-0.1c-1.6,0-3.2,0.2-4.8,0.5c-1.6,0.3-3.1,0.8-4.4,1.4s-2.4,1.4-3.2,2.2v38.3H1417.1
		L1417.1,517.2z"/>
                <path className="st1 second-load" d="M609.5,613.8v40.5c-3.7,0.6-7.5,1.1-11.4,1.4c-3.8,0.4-7.4,0.6-11.1,0.6h-0.3c-11.5,0-21.1-1.6-28.7-4.8
		c-7.7-3.2-13.5-8-17.3-14.5s-5.7-14.5-5.7-24.2s2-17.4,5.9-23.9c3.9-6.5,9.8-11.5,17.6-14.9s17.5-5.1,29.2-5.1s6.7,0.2,9.9,0.5
		c3.2,0.3,6.4,0.8,9.3,1.5v20.2c-2.7-0.6-5.4-1-8.2-1.3s-5.7-0.5-8.7-0.5c-7,0-12.6,0.8-16.9,2.5c-4.2,1.6-7.3,4.2-9.3,7.8
		c-1.9,3.5-2.9,8.1-2.9,13.7c0,5.6,0.9,9.4,2.6,12.8c1.7,3.4,4.4,5.9,7.9,7.5c3.5,1.6,8,2.5,13.4,2.5h1.6v-22.3H609.5z"/>
                <path className="st1 second-load" d="M621.6,655.2v-92.9H646v92.9H621.6z"/>
                <path className="st1 second-load" d="M688,656.4c-6.9,0-12.8-1.2-17.8-3.7s-8.8-6.2-11.5-11s-4.1-10.8-4.1-17.9s1.3-13.2,3.9-18.1s6.4-8.5,11.4-11
		s11-3.7,18.1-3.7s13.1,1.3,18.1,3.8s8.8,6.2,11.4,11.1c2.6,4.9,3.9,10.8,3.9,17.9s-1.3,13.1-4,18s-6.5,8.5-11.5,11
		s-10.9,3.7-17.8,3.7L688,656.4L688,656.4z M688,638.3c2,0,3.6-0.4,4.9-1.3c1.3-0.8,2.3-2.3,3-4.4c0.7-2.1,1-5,1-8.8s-0.3-6.9-1-9
		s-1.7-3.6-3-4.5c-1.3-0.8-2.9-1.3-4.9-1.3s-3.5,0.4-4.9,1.3c-1.3,0.8-2.3,2.3-3,4.4c-0.7,2.1-1,5.1-1,8.9s0.3,6.8,1,8.9
		c0.7,2.1,1.7,3.6,3,4.5C684.4,637.8,686,638.3,688,638.3L688,638.3L688,638.3z"/>
                <path className="st1 second-load" d="M756.9,656.4c-4.6,0-9.3-0.3-14.1-0.9c-4.8-0.6-9.3-1.6-13.5-3v-90.2h24.4v35.6h1.1c1.8-2.3,4-4,6.6-5.2
		s5.6-1.8,9-1.8s8.5,1.1,12.3,3.4c3.8,2.3,6.8,5.7,9.1,10.3s3.4,10.5,3.4,17.6c0,10.9-3.1,19.3-9.4,25.3c-6.2,5.9-15.9,8.9-29,8.9
		L756.9,656.4L756.9,656.4z M759.1,638.2c3.9,0,6.9-1.1,8.8-3.2c1.9-2.1,2.9-5.8,2.9-10.9s-0.4-6.2-1.2-8.2s-2-3.3-3.4-4.1
		c-1.5-0.8-3.3-1.2-5.5-1.2s-2.3,0-3.5,0.3s-2.3,0.6-3.4,1.1v25.5c0.8,0.2,1.6,0.3,2.5,0.4c0.9,0,1.8,0.2,2.8,0.2l0,0L759.1,638.2z"
                />
                <path className="st1 second-load" d="M857.6,602.4c-2.2-4.2-5.7-7.3-10.4-9.1s-10.9-2.7-18.5-2.7c-3.4,0-7,0.2-10.9,0.6c-3.9,0.5-7.6,1.1-11,2v18
		c2.7-0.8,5.7-1.4,8.8-1.8c3.2-0.4,5-0.6,8.4-0.6s6.1,0.3,8,0.9c1.9,0.7,3.2,1.6,4,3c0.5,0.9,0.8,2,1,3.4l-7.7,0.4
		c-10.1,0.5-17.3,2.6-21.8,6.1c-4.5,3.6-6.7,9.1-6.7,14.7c0,5.6,1.9,10.1,5.6,13.7c3.8,3.6,9,5.4,15.8,5.4l0.2-0.4
		c3.3,0,6.3-0.5,9-1.6c2.8-1.1,5-2.9,6.8-5.4h1l1.7,5.8h20v-35.1C860.9,612.5,859.8,606.7,857.6,602.4z M837.1,638.1
		c-1,0.6-2,0.9-3.1,1.2s-1.2,0.3-3.1,0.3c-1.9,0-3.4-0.4-4.4-1.3c-1.1-0.8-1.6-1.9-1.6-3.5c0-1.6,0.6-2.9,1.7-3.9
		c1.1-0.9,3-1.6,5.5-1.6l5-0.3V638.1z"/>
                <path className="st1 second-load" d="M871.5,655.2v-92.9h24.4v92.9H871.5z"/>
                <path className="st1 second-load" d="M962.7,656.1c-2.5,0-5.2,0-8.1-0.2c-2.9,0-5.9-0.3-8.9-0.5s-5.8-0.5-8.5-0.8v-82.4c2.9-0.7,6-1.2,9.3-1.7
		c3.4-0.5,6.8-0.9,10.3-1.1s7-0.4,10.4-0.4c16.4,0,28.8,3.5,37.2,10.5c8.3,7,12.5,18,12.5,33s-2,18.6-6,25s-10,11.1-18,14.1
		s-18.2,4.5-30.4,4.5L962.7,656.1L962.7,656.1z M969.6,636.1c4.9,0,9-0.8,12.3-2.3s5.7-4,7.3-7.4s2.4-8.1,2.4-13.9
		s-0.8-10.4-2.5-13.9s-4.2-5.9-7.4-7.4s-7.3-2.2-12.2-2.2s-2.2,0-3.6,0.2c-1.4,0-2.6,0.3-3.6,0.5V636c1.3,0,2.6,0,3.9,0h3.5h-0.1
		L969.6,636.1z"/>
                <path className="st1 second-load" d="M1026.6,655.2V592h19.9l1.9,8h1c1.5-3.2,3.7-5.5,6.4-6.9s5.8-2.1,9.3-2.1s1.9,0,2.8,0s1.8,0.2,2.4,0.3v22
		c-1-0.2-2.2-0.4-3.4-0.5s-2.4,0-3.4,0c-1.6,0-3.2,0.2-4.8,0.5c-1.6,0.3-3.1,0.8-4.4,1.4s-2.4,1.4-3.2,2.2v38.3H1026.6L1026.6,655.2
		z"/>
                <path className="st1 second-load" d="M1135.5,624.3c0-7-1.1-13-3.3-18c-2.2-5-5.4-8.9-9.8-11.5c-4.3-2.7-9.4-4-16.3-4s-12.7,1.2-17.5,3.9
		c-4.8,2.7-8.4,6.4-10.8,11.3c-2.5,4.9-3.7,9.8-3.7,17.4c0,7.6,1.3,13.9,3.9,18.7c2.6,4.9,6.7,8.5,12.3,10.8
		c5.5,2.3,12.7,3.5,21.6,3.5v0.1h0.3c3.1,0,6.3-0.1,9.5-0.4c3.2-0.3,6.5-0.8,10.1-1.5v-17.9c-2.6,0.5-5.3,0.9-8.1,1.2
		c-2.8,0.3-3.2,0.5-8,0.5c-4.8,0-8.4-0.3-10.9-1c-2.5-0.6-4.1-1.7-5-3.2c-0.6-1-1-2.3-1.2-3.8h36.9V624.3z M1098.5,616.9
		c0.1-2.6,0.3-4.7,0.8-6.2c0.5-1.7,1.3-3,2.4-3.7c1-0.7,2.5-1.1,4.1-1.1c1.6,0,3,0.4,4.1,1.1c1,0.7,1.8,1.9,2.3,3.7
		c0.4,1.5,0.7,3.6,0.7,6.2H1098.5z"/>
                <path className="st1 second-load " d="M1197.1,602.4c-2.2-4.2-5.7-7.3-10.4-9.1c-4.7-1.8-10.9-2.7-18.5-2.7c-3.4,0-7,0.2-10.9,0.6
		c-3.9,0.5-7.6,1.1-11,2v18c2.7-0.8,5.7-1.4,8.8-1.8c3.2-0.4,5-0.6,8.4-0.6s6.1,0.3,8,0.9c1.9,0.7,3.2,1.6,4,3c0.5,0.9,0.8,2,1,3.4
		l-7.7,0.4c-10.1,0.5-17.3,2.6-21.8,6.1c-4.5,3.6-6.7,9.1-6.7,14.7c0,5.6,1.9,10.1,5.6,13.7c3.8,3.6,9,5.4,15.8,5.4l0.2-0.4
		c3.3,0,6.3-0.5,9-1.6c2.8-1.1,5-2.9,6.8-5.4h1l1.7,5.8h20v-35.1C1200.4,612.5,1199.3,606.7,1197.1,602.4z M1176.6,638.1
		c-1,0.6-2,0.9-3.1,1.2s-1.2,0.3-3.1,0.3s-3.4-0.4-4.4-1.3c-1.1-0.8-1.6-1.9-1.6-3.5c0-1.6,0.6-2.9,1.7-3.9c1.1-0.9,3-1.6,5.5-1.6
		l5-0.3V638.1z"/>
                <path className="st1 second-load" d="M1308.1,617.4v37.7h-24.4v-36.2c0-2.7-0.5-4.6-1.6-5.6s-2.4-1.5-4.2-1.5c-1.8,0-2.1,0-3,0.4
		c-0.9,0.2-1.7,0.6-2.4,1c-0.3,0.2-0.6,0.4-0.9,0.6c0,1,0.1,2,0.1,3v38.6h-23.9v-36.3c0-2.7-0.5-4.6-1.4-5.6s-2.3-1.5-4-1.5
		c-1.7,0-1.9,0-2.8,0.4c-0.9,0.4-1.7,0.6-2.4,1c-0.7,0.4-1.3,1-1.8,1.6v40.4H1211v-0.2h-0.1V592h20.1l1.6,6h1c2.1-2.3,4.6-4,7.5-5.2
		s6.2-1.8,9.9-1.8s7.4,0.9,10.6,2.6c2.1,1.2,4,2.9,5.5,5.1c2.4-2.5,5-4.4,8.1-5.6c3.4-1.4,7-2.2,10.6-2.2c3.6,0,8.1,0.9,11.5,2.6
		c3.4,1.8,6,4.6,7.9,8.5C1307.1,605.9,1308.1,611,1308.1,617.4z"/>
                <path className="st0 first-load" d="M469.7,424L469.7,424c5.8,0,10.5,4.7,10.5,10.5v211c0,5.8-4.7,10.5-10.5,10.5l0,0c-5.8,0-10.5-4.7-10.5-10.5
	v-211C459.2,428.7,463.9,424,469.7,424z"/>
            </svg>


        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    height: 100vh;
    position: fixed;
    background-color: #F8F6F5;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 9999999999999999999;
    display: flex;
    align-items: center;
    justify-content: center;

    .first-load {
        stroke: #cb5f28;
        opacity: 0;
    }

    .second-load {
        stroke: #145c8c;
        opacity: 0;
    }

    svg {

        @media (min-width: 1024px) {
            height: 400px;
        }

        path, polygon {
            fill: none;
            stroke: ${text};
        }

    }

    @media (max-width: 600px) {

    }
`;

export default React.memo(PageTransition);
