import {createGlobalStyle, css} from "styled-components";
import {hardGray, hover, softWhite, text, Transition, white} from "./globalStyleVars";


function createCSS() {
    let styles = "";

    for (let i = 2; i < 20; i += 1) {
        styles += `
        .anim-active.fade-up:nth-child(${i}) {
          transition-delay: ${i * 0.12}s;
        }
     `;
    }

    for (let a = 2; a < 100; a += 1) {
        styles += `
        .anim-active.fade-right span:nth-child(${a}) {
          transition-delay: ${a * 0.03}s;
        }
     `;
    }

    return css`
        ${styles}
    `;
}

export default createGlobalStyle`
    ::root {
        --animationDistance: -100px;
        --animationDuration: 12s;
    }
    ${createCSS()}
    #root {
        min-height: 100vh;
        overflow-x: hidden;
    }

    html{
        scroll-behavior: smooth;
    }

    html::-webkit-scrollbar {
        width: 5px;
        height: 16px;
        display: none;
    }

    html::-webkit-scrollbar-thumb {
        background: ${hover};
        border-radius: 5px;
        display: none;
        
    }

    html::-webkit-scrollbar-track {
        display: none;
        background: ${text};
        //background: linear-gradient(90deg,#434343,#434343 1px,#111 0,#111);
    }


    .modal-static.show {
        overflow: hidden !important;

        .modal-dialog {
            transform: scale(1) !important;
        }
    }

    body.menu-is-open {
        .main-menu__items ul li a svg {
            transform: rotate(180deg);
        }
    }

    body {
        font-style: normal;
        font-weight: 400;
        margin: 0;
        color: ${text};
        padding: 0;
        overflow-x: hidden;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 16px;
        min-height: 100vh;
        scroll-behavior: smooth;
        text-rendering: optimizeSpeed;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

    }


    //typography
    h1 {
        font-size: 8.75rem;
        line-height: 10rem;
        letter-spacing: -2.8px;
        font-weight: 400;
        @media (max-width: 767px) {
            letter-spacing: -0.96px;
            font-size: 3rem;
            line-height: 3.25rem;
        }
    }

    h2 {
        font-size: 5rem;
        line-height: 5.625rem;
        letter-spacing: -1.6px;
        font-weight: 400;
        @media (max-width: 767px) {
            letter-spacing: -0.96px;
            font-size: 3rem;
            line-height: 3.25rem;
        }
    }

    h3 {
        font-size: 3.75rem;
        line-height: 4.063rem;
        letter-spacing: -1.2px;
        font-weight: 400;
        @media (max-width: 767px) {
            letter-spacing: -0.72px;
            font-size: 2.25rem;
            line-height: 2.5rem;
        }
    }

    h4 {
        font-size: 2rem;
        line-height: 2.5rem;
        letter-spacing: -0.32px;
        font-weight: 500;
    }

    h5 {
        font-size: 1.75rem;
        line-height: 2rem;
        font-weight: 500;
        letter-spacing: -0.28px;
    }

    h6 {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 500;
        letter-spacing: -0.24px;
    }

    a {
        transition: color .3s ease;
        text-decoration: none;

        &:hover {
            color: ${hover} !important;
            text-decoration: none;
            outline: none;
            box-shadow: none;
        }

        &:focus {
            text-decoration: none;
            outline: none;
            box-shadow: none;
            color: ${text};
        }
    }

    ::selection {
        background: ${hover};
        color: #FFF;
    }

    p, a, h1, h2, h4, h3, h5, h6 {
        margin: 0;
    }


    ul {
        margin: 0;
        padding: 0
    }

    li {
        list-style: none;
    }

    img {
        max-width: 100%;
        object-fit: contain;
    }


    .btn:focus, button:focus, button:active:focus, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
        outline: none;
        box-shadow: none;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: 1px solid rgba(0, 0, 0, 0);
        -webkit-text-fill-color: #000;
        -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
        transition: background-color 5000s ease-in-out 0s;
    }


    table {
        width: 100%;
        gap: 20px;
        margin-bottom: 60px;

        tr:first-child {
            border-bottom: 1px solid rgba(54, 50, 49, 0.1);
            padding-top: 0;
        }

        tr {
            border-bottom: 1px solid rgba(54, 50, 49, 0.1);
            -webkit-box-pack: start;
            justify-content: flex-start;
            padding: 20px 0;
            display: flex;
            flex-wrap: wrap;
            gap: 30px;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;

            td {
                max-width: calc(50% - 30px);
                flex: 0 0 calc(50% - 30px);
            }
        }


    }

    form div {
        position: relative;
    }

    .form-control {
        box-shadow: none;
        outline: 0;
        border-radius: 0;

        &:focus {
            box-shadow: none;
        }
    }

    .p-0 {
        padding: 0 !important;
    }

    .pl-0 {
        padding-left: 0;
    }

    .pr-0 {
        padding-right: 0;
    }

    .pt-200 {
        padding-top: 200px;
        @media (max-width: 767px) {
            padding-top: 120px;
        }
    }

    .pb-200 {
        padding-bottom: 200px;
        @media (max-width: 767px) {
            padding-bottom: 120px;
        }
    }

    .pt-160 {
        padding-top: 160px;
        @media (max-width: 767px) {
            padding-top: 120px;
        }
    }

    .pb-160 {
        padding-bottom: 160px;
        @media (max-width: 767px) {
            padding-bottom: 120px;
        }
    }

    .pt-150 {
        padding-top: 150px;
        @media (max-width: 767px) {
            padding-top: 100px;
        }
    }

    .pb-150 {
        padding-bottom: 150px;
        @media (max-width: 767px) {
            padding-bottom: 100px;
        }
    }

    .pb-130 {
        padding-bottom: 130px;
        @media (max-width: 767px) {
            padding-bottom: 100px;
        }
    }

    .pt-100 {
        padding-top: 100px;
        @media (max-width: 767px) {
            padding-top: 60px;
        }
    }

    .pb-100 {
        padding-bottom: 100px;
        @media (max-width: 767px) {
            padding-bottom: 60px;
        }
    }

    .pt-80 {
        padding-top: 80px;
        @media (max-width: 767px) {
            padding-top: 40px;
        }
    }

    .pb-80 {
        padding-bottom: 80px;
        @media (max-width: 767px) {
            padding-bottom: 40px;
        }
    }

    .mt-20 {
        margin-top: 20px;
    }

    .mt-40 {
        margin-top: 40px;
    }

    .mt-60 {
        margin-top: 60px;
    }


    @media (min-width: 1500px) {
        .container {
            min-width: 85%;
            margin: auto;
        }
    }

    @media (max-width: 1199px) and (min-width: 768px) {
        .container, .container-lg, .container-md, .container-sm {
            max-width: 90%;
            margin: auto;
        }
    }


    @media (max-width: 767px) {
        .container, .container-sm {
            max-width: 100%;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }


    //react select
    .css-yk16xz-control, .css-1pahdxg-control {
        height: 50px;
        border-radius: 0 !important;
        padding-left: 5px;
        font-size: 16px;
        outline: none !important;
        border-color: #D9D9D9 !important;
        box-shadow: none !important;

        .css-1wa3eu0-placeholder {
            font-weight: 300;
            line-height: 21px;
            color: rgba(0, 0, 0, 0.5);
            outline: none;
        }

        .css-1okebmr-indicatorSeparator {
            display: none;
        }

        .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
            margin-right: 10px;
        }
    }

    .css-2613qy-menu {
        border-radius: 0 !important;
        margin-top: 0 !important;
    }


    .info-window {
        max-width: 200px;
    }

    .gm-style-iw {
        border-radius: 0 !important;
    }

    .swiper-pagination-bullet {
        outline: none;
    }

    .css-nmuc1a-menu {
        z-index: 5 !important;
    }


    .map-info__img {
        img {
            height: 100px;
            margin-bottom: 12px;
            object-fit: cover;
        }
    }

    .map-info__content {
        h4 {
            font-size: 20px;
        }

        p {
            margin-bottom: 5px;
        }
    }

    .overlay {
        position: fixed;
        height: 100vh;
        width: 100%;
        //background-color: rgba(0,0,0,0.5);
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 9;
        display: none;

        &.show {
            display: block;
        }
    }

    .form-control.has-error {
        border-color: #FF0000 !important;
    }

    .has-error .find-retainer-filter__control {
        border-color: #FF0000 !important;
    }

    //preloader
    .content-loader {
        position: absolute;
        height: 70%;
        width: 70%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    .loading-before-submit {
        position: fixed;
        height: 100vh;
        width: 100%;
        bottom: 0;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.65);
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: 40px;
        }
    }


    .swiper-slide {
        height: auto;
    }

    .slick-slide {
        div {
            outline: none !important
        }
    }

    button, button:active, button:focus, button:focus-visible {
        outline: none !important;
        box-shadow: none !important;
    }


    .hover {
        position: relative;
        overflow: hidden;

        span {
            z-index: 2;
        }

        &:after {
            content: '';
            position: absolute;
            height: 0;
            width: 0;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            background-color: ${hover};
            transition: all .5s ease;
            border-radius: 19px;
        }

        &:hover {
            &:after {
                height: 100%;
                width: 100%;
            }
        }
    }


    .modal-backdrop {
        background-color: rgba(0, 0, 0, 70%);
        min-width: 100%;
        z-index: 99999;

        &.show {
            opacity: 1;
        }
    }

    .modal {
        z-index: 99999999;

    }

    .valid {
        color: ${hover};
        position: absolute;
        font-size: 12px;
        top: 44px;
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }


    //menu fixed
    .scroll-down .menu-desktop {
        transform: translate3d(0, -100%, 0);
    }

    .scroll-up .menu-desktop {
        background-color: #fff;

        .menu-desktop__hamburger__lines {
            &__inner .line {
                background-color: #191818;
            }

            p {
                color: #191818;
            }
        }

        .menu-desktop__hamburger .controller svg {
            path {
                fill: #191818;
            }

            line {
                stroke: #191818;
            }
        }

        .dc-btn a {
            color: #191818;

            &:hover {
                color: #191818 !important;
            }

            &:after, &:before {
                box-shadow: 0 0 0 1px #191818;
            }
        }
    }

    .menu-v2 .menu-desktop {
        background-color: #fff;

        .menu-desktop__hamburger__lines {
            &__inner .line {
                background-color: #191818;
            }

            p {
                color: #191818;
            }
        }

        .menu-desktop__hamburger .controller svg {
            path {
                fill: #191818;
            }

            line {
                stroke: #191818;
            }
        }

        .dc-btn a {
            color: #191818;

            &:hover {
                color: #191818 !important;
            }

            &:after, &:before {
                box-shadow: 0 0 0 1px #191818;
            }
        }
    }

    .form-control:disabled {
        background-color: transparent;
    }

    @media (max-width: 600px) {
        .prevent-overflow {
            overflow: hidden;
            height: 100vh;
        }
    }

    .Toastify__toast-container {
        z-index: 99999999;
    }

    .mobile-menu {
        #fb-root, .fb_reset {
            display: none !important;
            opacity: 0 !important;
            visibility: hidden !important;
        }
    }

    .slick-slide {
        -webkit-transform: translate3d(0, 0, 0);
    }


    //------------------------animation
    .split-parent {
        overflow: hidden;
        will-change: transform;
    }

    .parallax-xx, .serviceSingle, .parallax-xr, .parallax-x, .fixed-icon, .ticker__element{
        will-change: transform;
    }
    .split-child, .split-title {
        overflow: hidden;
    }


    .error-message {
        font-size: 12px;
        font-weight: 300;
        letter-spacing: -0.2px;
        color: #FF0000;
    }


    .page-transition-enter {
        opacity: 0;
    }

    .page-transition-enter-active {
        opacity: 1;
        transition: opacity 300ms;
    }

    .page-transition-exit {
        opacity: 1;
    }

    .page-transition-exit-active {
        opacity: 0;
        transition: opacity 300ms;
    }


    .reveal {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        img {
            transform-origin: left;
        }

        .global-image {
            background: transparent;
        }
    }

    #smooth-content {
        will-change: transform;
    }

    .page-transition {
        display: none;
    }

    form {
        .form-control {
            border: none;
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            background-color: transparent !important;
            padding: 0 0 20px 0;
            margin-bottom: 40px;
            outline: none;
            color: #ffffff;
            box-shadow: none;

            &::placeholder {
                color: #999999;
            }

            &:focus {
                border-color: #FFF;
            }
        }

        textarea {
            min-height: 80px;
            max-height: 80px;
        }
    }

    .modify-footer .footer {
        @media (min-width: 600px) {
            padding-top: 200px;
        }
    }

    //global form
    .global-popup form .form-control {
        border-bottom: 1px solid rgba(10, 14, 18, 0.3);
        color: ${text};
        padding: 0 0 15px 0;
        margin-bottom: 30px;

        &::placeholder {
            color: #999999 !important;
            text-transform: capitalize;
        }

        &:focus {
            border-bottom: 1px solid ${text};
        }
    }

    .services {
        background-color: ${text};

        .working, .insights {
            padding-top: 0;
        }

        .amenities {
            @media (max-width: 992px) {
                padding-top: 0;
            }
        }
    }

    .career-detail {
        background-color: ${text};
        padding-top: 140px;
        padding-bottom: 150px;
        overflow: hidden;
    }

    .team-bio {
        background-color: ${text};
        padding-top: 140px;
    }

    .sections {
        min-width: 100%;
    }

    .menu-opened {
        height: 100vh;
        overflow: hidden;

        .main-menu {
            .container {
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            }
        }

        .main-menu__hambuerger {
            .menu-open {
                display: none;
            }

            img.menu-close {
                display: block !important;
            }
        }
    }

    .scroll-down .main-menu {
        transform: translate3d(0, -100%, 0);
    }

    .scroll-down .main-menu-mobile .main-menu-mobile__bar {
        transform: translate3d(0, -100%, 0);
    }


    .scroll-down .navigation-menu-two {
        transform: translate3d(0, -100%, 0);
        
    }


    .scroll-up .navigation-menu-two {
        transform: translate3d(0, 0, 0);

    }

    .menu-v2 {
        .main-menu-mobile .main-menu-mobile__bar {
            background: white;
            transform: translate3d(0, 0, 0) !important;
            border-bottom: 1px solid rgba(54, 50, 49, 0.1);

        }

        .main-menu-mobile__bar__logo {
            a {
                &:first-child {
                    display: none;
                }

                &:last-child {
                    display: block !important;
                }
            }
        }
    }

    .scroll-up {
        .main-menu-mobile .main-menu-mobile__bar {
            background: white;
            border-bottom: 1px solid rgba(54, 50, 49, 0.1);

        }

        .main-menu-mobile__bar__logo {
            a {
                &:first-child {
                    display: none;
                }

                &:last-child {
                    display: block !important;
                }
            }
        }
    }


    .scroll-up .main-menu {
        background-color: ${softWhite};

        .main-menu__items ul li a:after {
            background: ${text};
        }

        .container {
            position: relative;

            &:after {
                content: '';
                background: rgba(54, 50, 49, 0.1);
                left: 0;
                right: 0;
                height: 1px;
                width: calc(100% - 30px);
                margin: 0 auto;
                position: absolute;
                bottom: 0;

            }
        }

        .main-menu__logo {
            a {
                img {
                    &:first-child {
                        display: none;
                    }

                    &:last-child {
                        display: block;
                    }
                }
            }
        }

        .parent-title{
            color: ${text} !important;
            
        }
        .main-menu__items ul li a {
            color: ${text};

            &:hover {
                #Line_5, #Line_6 {
                    stroke: ${white};
                }

            }
        }

        #Ellipse_20, #Line_5, #Line_6 {
            stroke: ${text};
        }

        .migration-button {
            background: ${text};

            #Ellipse_17 {
                fill: ${text};
            }

            #Bd-2, #Au-2 {
                fill: ${white};
            }

            &:hover {
                background: ${hover};
            }
        }

        .search {
            #Ellipse_16 {
                stroke: ${text};
            }

            #Path_9364, #Path_9365 {
                fill: ${text};
            }
        }

    }

    .menu-v2 .main-menu {
        background-color: ${softWhite};

        .main-menu__items ul li a:after {
            background: ${text};
        }

        .container {
            position: relative;

            &:after {
                content: '';
                background: rgba(54, 50, 49, 0.1);
                left: 0;
                right: 0;
                height: 1px;
                width: calc(100% - 30px);
                margin: 0 auto;
                position: absolute;
                bottom: 0;

            }
        }

        .main-menu__logo {
            a {
                img {
                    &:first-child {
                        display: none;
                    }

                    &:last-child {
                        display: block;
                    }
                }
            }
        }
        .parent-title{
            color: ${text} !important;

        }
        .main-menu__items ul li a {
            color: ${text};

            &:hover {
                #Line_5, #Line_6 {
                    stroke: ${white};
                }

            }
        }

        #Ellipse_20, #Line_5, #Line_6 {
            stroke: ${text};
        }

        .migration-button {
            background: ${text};

            #Ellipse_17 {
                fill: ${text};
            }

            #Bd-2, #Au-2 {
                fill: ${white};
            }

            &:hover {
                background: ${hover};
            }
        }

        .search {
            #Ellipse_16 {
                stroke: ${text};
            }

            #Path_9364, #Path_9365 {
                fill: ${text};
            }
        }

    }

    /* modify menu v2 */
    .menu-v2 {
        .main-menu {
            &__logo {
                img {
                    &:nth-of-type(1) {
                        display: none;
                    }

                    &:nth-of-type(2) {
                        display: block !important;
                    }
                }
            }

            &__items ul li a {
                color: #231E21 !important;

                &:hover {
                    color: ${hover} !important;
                }
            }
        }

        &.scroll-up .main-menu {
            background-color: #FFF !important;
        }
    }

    .merchant {
        .celebrity {
            background-color: #F7F7F5;
        }

        .cta-3-col {
            background-color: #fff;
        }
    }

    .user {
        .cta-3-col {
            background-color: #F7F7F5;
            padding-top: 160px;
            @media (max-width: 991px) {
                //margin-top: 100px;
                padding-top: 100px;
            }
        }
    }

    .creators {
        .app-download:after {
            background-color: #FFF;
        }
    }


    //video modal
    .modal-video {
        background-color: transparent;
        height: 100vh;
        z-index: 99999;

        .modal-dialog {
            height: 100vh;
            background-color: transparent;
            min-width: 100%;
            margin: 0;
        }

        .modal-body {
            height: 100vh;
        }

        .modal-content {
            background-color: transparent;
        }

        iframe {
            height: 60vh;
            width: 60vw;
            margin: auto;
            position: absolute;
            inset: 0;
        }

        .close-modal {
            position: absolute;
            top: 40px;
            right: 30px;
            height: 30px;
            cursor: pointer;
            z-index: 99;
        }

        @media (max-width: 768px) {
            .modal-content {
                //padding: 0 20px;

                iframe {
                    width: 90vw;
                    height: 60vh;
                }

                .close-modal {
                    top: 80px;
                    right: 20px;
                }
            }
        }
        @media (max-width: 550px) {
            .modal-content iframe {
                width: 90vw;
                height: 40vh;
            }
        }
    }

    .list-count {
        margin: 0 0 60px;
        counter-reset: listItem 0;

        li {
            position: relative;
            padding-bottom: 20px;
            margin-bottom: 20px;
            padding-left: 45px;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.16px;

            &:before {
                content: counter(listItem);
                counter-increment: listItem 1;
                position: absolute;
                left: 0;
                top: 16px;
                margin-top: -15px;
                box-sizing: content-box;
                font-size: 12px;
                line-height: 16px;
                font-weight: 400;
                color: white;
                z-index: 1;
                height: 24px;
                width: 24px;
                border-radius: 50%;
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
            }

            &:after {
                position: absolute;
                left: 0;
                top: 0;
                content: "";
                background: ${hover};
                height: 24px;
                width: 24px;
                border-radius: 50%;
            }


        }

        &.two {
            li {
                display: flex;
                flex-direction: column;

                b {
                    display: block;
                    margin-bottom: 10px;
                    font-weight: 500;
                }
            }
        }
    }

    .list-course {
        li {
            display: flex;
            flex-direction: column;
            margin-bottom: 60px;

            &:last-child {
                margin-bottom: 0;
            }

            h6 {
                margin-bottom: 20px;
            }

            @media (max-width: 992px) {
                margin-bottom: 30px;
            }
        }
    }

    .list {
        margin-top: 0;
        margin-bottom: 60px;

        li {
            border-bottom: 1px solid rgba(54, 50, 49, 0.1);
            font-size: 16px;
            font-weight: 400;
            letter-spacing: -0.16px;
            position: relative;
            margin-bottom: 20px;
            padding-left: 28px;
            line-height: 20px;
            color: rgb(4, 5, 4);
            padding-bottom: 15px;

            strong {
                font-weight: 500;
            }

            &:after {
                position: absolute;
                left: 0;
                top: 5px;
                content: "";
                background: ${hover};
                height: 8px;
                width: 8px;
                border-radius: 50%;
            }
        }
    }

    .blog-button {
        .slider-nav {
            ul {
                display: flex;
                gap: 15px;
            }

            li {
                height: 40px;
                width: 40px;
                background: transparent;
                border: 1px solid ${text};
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                position: relative;
                overflow: hidden;

                svg {
                    position: relative;
                    z-index: 3;

                    line {
                        transition: 0.7s all ease;

                    }
                }

                &:after {
                    content: '';
                    position: absolute;
                    inset: 0;
                    background: ${hover};
                    border-radius: 50%;
                    transition: 0.7s all ease;
                    z-index: 2;
                    transform: scale(0);
                }

                &:hover {
                    border-color: ${hover};

                    &:after {
                        transform: scale(1);
                    }

                    line {
                        stroke: white;
                    }
                }
            }
        }
    }
    .stop-scroll.scroll-down{
        overflow: hidden;
    }
    .stop-scroll.scroll-down .main-menu-mobile .main-menu-mobile__bar{
        transform: translate3d(0,0,0) !important;
    }
    
    //landing page popup 
    .notice_modal {
        transition: 0.5s all ${Transition};

        .modal-dialog {
            max-width: 60%;
            height: 100%;
            overflow: hidden;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: 992px) {
                max-width: 80%;
            }
            @media (max-width: 767px) {
                padding: 0 15px;
                max-width: 100%;

            }
        }

        .modal-content {
            height: 80%;
            display: flex;
            align-items: center;
            text-align: center;
            background-image: url("/images/static/collage.png");
            background-repeat: no-repeat;
            background-position: bottom;
            background-size: cover;


        }

        .modal-body {
            padding: 40px 70px;
            text-align: center;
            @media (max-width: 767px) {
                padding: 70px 30px;
            }
        }

        .region {
            width: 100%;
            max-width: 70%;

            .filter__control {
                background: ${softWhite};
                margin-bottom: 10px;
            }

            p.note {
                font-size: 12px;
                letter-spacing: -0.12px;
                line-height: 16px;
                color: ${hardGray};
                text-align: left;
            }

            @media (max-width: 767px) {
                max-width: 100%;
                .selector-custom-nzuac {
                    min-width: unset;
                }
            }
        }

        .notice_image {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        .content {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .gph_modal.form-modal.popup-version-one {

        .modal-body {
            padding: 0;
        }

        padding-left: 0 !important;

        .close-icon-modal {
            position: fixed;
            z-index: 9999;
            left: 0;
            right: 0;
            display: flex;
            justify-content: flex-end;

            .for-close {
                position: relative;
                right: 70px;
                top: 30px;


                @media (max-width: 992px) {
                    right: 30px;
                    .modal-close {
                        svg {
                            background: ${softWhite};
                        }
                    }
                }
            }
        }

        .container {
            padding: 0;
            max-width: 100%;
            margin: 0 !important;
        }

        .modal-dialog {
            margin: 0;
            width: 100%;
            border-radius: 0;
            max-width: 100%;

            .modal-content {
                border: 0px;
                border-radius: 0;
                background: transparent;


                .modal-close {
                    height: auto;
                    width: auto;
                    border-radius: 0;
                    right: 15px;
                }

                .modal-data__content {
                    border: none;
                }

                .modal-main-content {
                    height: calc(100vh - 160px);
                    overflow-y: scroll;
                    padding-bottom: 90px !important;
                    margin-top: 80px;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    /* Hide scrollbar for IE, Edge and Firefox */
                    -ms-overflow-style: none; /* IE and Edge */
                    scrollbar-width: none; /* Firefox */

                    .profile-info {
                        padding-bottom: 40px;

                        .img-wrp {
                            padding-top: calc(400 / 310 * 100%);
                            position: relative;
                            overflow: hidden;
                        }

                        h3 {
                            color: ${white};
                            margin: 40px 0 0;

                        }

                        p {
                            color: ${white};
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 21px;
                            margin: 0 0 20px;
                        }
                    }

                    .profile-details {
                        h2 {
                            color: #F1EEE9;
                            margin-bottom: 35px;
                        }

                        p {
                            color: ${white};
                            font-size: 18px;
                            font-weight: 500;
                        }
                    }

                    @media (max-width: 992px) {
                        display: block !important;
                        .col-sm-4, .col-sm-8 {
                            flex: 0 0 100%;
                            max-width: 100%;
                        }

                        .profile-info {
                            margin-top: 40px;

                            .social {
                                justify-content: flex-start;
                            }
                        }
                    }
                }
            }
        }

        @media (min-width: 1024px) {
            .modal-body .modal-data {
                height: auto !important;
                width: 100%;

            }

            .form_wrapper form {
                justify-content: space-between !important;
            }

            .row .form-group {
                margin-bottom: 0 !important;
            }

            .single_image_image_wrp {
                height: auto !important;
                padding-top: 100vh !important;
            }

            .list_with_form {
                height: auto !important;
                width: 100%;
            }

        }
        @media (max-width: 1440px) and (min-width: 1024px) {
            .form_wrapper {
                padding: 60px 70px 60px 60px !important;

                .textarea {
                    min-height: 30px !important;
                    max-height: 30px !important;
                }
            }

            .single_image_image .content_wrap .single_image_image_wrp .single_content_wrp {
                padding: 60px 30px 60px 60px !important;
            }
        }

        @media (max-width: 992px) {
            #ListWithForm {
                width: 100%;
            }

            .col-md-5, .form-column {
                flex: 0 0 100%;
                max-width: 100%;
            }

            .container-fluid {
                overflow: hidden;
                padding: 0 15px !important;
            }
        }
        @media (max-width: 767px) {
            .container-fluid {
                overflow: hidden;
                padding: 0 15px !important;
            }


            .single_image_image .content_wrap .single_image_image_wrp .single_content_wrp {
                padding-top: 40px !important;
            }
        }
    }

    
 
    .versity-list-row .col .image-wrapper p{
        font-size: 0.8rem !important;
        letter-spacing: 0.2px !important;
    }
    .form-group{
        position: relative;
    }
    .error-message{
        position: absolute;
        bottom: -25px;
    }

    @media (min-width: 1500px) {
        .gph_modal.form-modal.popup-version-one.modal_form_start  .form_wrapper form {
            gap: 40px !important;
            justify-content: flex-start !important;
        }
    }

    @media (min-width: 1024px) {
        .gph_modal.form-modal.popup-version-one.modal_form_start  .form_wrapper form {
            gap: 25px;
            justify-content: flex-start !important;
        }
    }
    
    @media(max-width: 767px){
        .modal_form_start.gph_modal.form-modal.popup-version-one{
            .single_image_image .content_wrap .single_image_image_wrp{
                padding-top: calc(500 / 370* 100%) !important;
            }
            .single_image_image .content_wrap .single_image_image_wrp .single_content_wrp{
                padding-top: 55px !important;
            }
        }

        .form_wrapper .row .col-md-6{
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
    }

    .swiper-fade.swiper-free-mode .swiper-slide {
        transition-timing-function: ease-out;
    }
    .swiper-fade .swiper-slide {
        pointer-events: none;
        transition-property: opacity;
    }
    .swiper-fade .swiper-slide .swiper-slide {
        pointer-events: none;
    }
    .swiper-fade .swiper-slide-active {
        pointer-events: auto;
    }
    .swiper-fade .swiper-slide-active .swiper-slide-active {
        pointer-events: auto;
    }

    .scroll-up.stop-scroll{
        .main-menu-mobile__bar{
            background-color: #363231 !important;    
        }
        .main-menu-mobile.menu-open{
            .main-menu-mobile__bar__logo{
                a:first-child{
                    display: block !important;
                }
                a:last-child{
                    display: none !important;
                }
            }
        }
        
    }
    .menu-v2.scroll-up.stop-scroll{
        .main-menu-mobile__bar{
            background-color: #363231 !important;    
        }
        .main-menu-mobile.menu-open{
            .main-menu-mobile__bar__logo{
                a:first-child{
                    display: block !important;
                }
                a:last-child{
                    display: none !important;
                }
            }
        }
        
    }

`;
